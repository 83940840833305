import React from 'react';

import { Container } from './styles';

import './styles.css';

const FallbackSpinner: React.FC = () => {
  return (
    <Container>
      <div className="loader" />
    </Container>
  );
};

export default FallbackSpinner;
